import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import Paper from '../Dashboard/Paper';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { areEqualProps, get2DigitFormattedNumber } from '../../actions/commonActions';
import Typography from '../Typography';
import { filterChartData, getItemType } from '../../utils/constants/severity';
import MuiTooltip from '../MuiComponents/MuiTooltip';
import { tooltipTitle } from '../../utils/constants/tooltips';
import Info from '../Info';

const COLORS = [
  'var(--primary-500)',
  'var(--tertiary-600)',
  'var(--functional-purple)',
  'var(--secondary-700)',
  'var(--grey2)',
];

const CounterChartWidget = (props) => {
  const {
    data = [],
    title,
    colors = COLORS,
    className,
    labelPosition = 'right',
    shadow = false,
    chartClassName = null,
    titleClassName = '',
    showLabelCount,
    labelFullWidth = false,
    showEmpty = false,
    showTooltip = false,
    height= 130,
    showCustomLegendText= false,
    showNoDataToolTip=false
  } = props;

  const total = showLabelCount
    ? data?.reduce(
        (acc, obj) =>
          obj?.name === showLabelCount ? Number(acc) + Number(obj?.value) : Number(acc) + 0,
        0
      )
    : data?.reduce((acc, obj) => Number(acc) + Number(obj?.value), 0) || 0;
  const chartData = filterChartData(data);
  return (
    <Paper shadow={shadow} className={`${className}`}>
      <Typography variant={'subtitle1'} className={`${titleClassName} text-headings px-5`}>
        {title}
        {showNoDataToolTip && <Info tooltip={tooltipTitle.no_data} />}
      </Typography>
      {(!showEmpty || total > 0) && (
        <div className="grid grid-cols-12">
          <div className={`${labelPosition === 'right' ? 'col-span-6' : 'col-span-12'}`}>
            <div className={`h-[110px] mt-4 ${chartClassName}`}>
              <ResponsiveContainer>
                <PieChart>
                  <circle cx={'50%'} cy={'50%'} r={50} fill={'var(--background)'} />
                  <text
                    x={'50%'}
                    y={'50%'}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    className={'text-[25px] font-semibold fill-body'}
                  >
                    {get2DigitFormattedNumber(total)}
                  </text>
                  <Pie
                    isAnimationActive={true}
                    startAngle={90}
                    endAngle={-270}
                    data={chartData}
                    innerRadius={40}
                    outerRadius={50}
                    fill="#000"
                    paddingAngle={5}
                    cornerRadius={5}
                    dataKey="value"
                  >
                    {chartData.map((entry, index) => {
                      const itemType = getItemType(entry);
                      return <Cell key={`cell-${index}`} fill={colors[itemType]?.color} />;
                    })}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
          {labelFullWidth ? (
            <div className="col-span-12 mt-4">
              <div className="flex items-center flex-align-center">
              <div className={`flex flex-col`} style={{...(height && { height: `${height}px` } )}}>                  
                {data?.map((item, index) => {
                    const itemType = getItemType(item);
                    return (
                      <div className="flex items-center flex-[100%]" key={index}>
                        <div
                          className="inline-block w-6 h-1 rounded-[10px] align-middle mr-2.5"
                          style={{ background: colors[itemType]?.color }}
                        ></div>
                        <Typography variant={'caption'} className="capitalize">
                          <span className="mr-1.5">{item.value}</span>
                          <span className="capitalize">
                            {item?.displayName
                              ? showCustomLegendText ? item?.displayName : (item?.displayName?.replace('_', ' ')?.[0]?.toUpperCase() + item?.displayName?.replace('_', ' ')?.toLowerCase()?.slice(1))
                              : showCustomLegendText ? item?.name : (item?.name?.replace('_', ' ')?.[0]?.toUpperCase() + item?.name?.replace('_', ' ')?.toLowerCase()?.slice(1))}
                          </span>
                        </Typography>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div
              className={`${
                labelPosition === 'right' ? 'col-span-6 flex items-center pt-4' : 'col-span-12 pt-4'
              }`}
            >
              <div
                className={`${
                  labelPosition === 'right'
                    ? 'flex flex-wrap items-center gap-x-4 flex-align-center'
                    : labelFullWidth
                    ? 'flex flex-wrap items-center h-[130px] gap-x-4 flex-align-center'
                    : 'flex flex-wrap items-center gap-x-4 flex-align-center px-3'
                }`}
              >
                {data?.map((item, index) => {
                  const itemType = getItemType(item);
                  console.log(item)
                  return showTooltip ? (
                    <MuiTooltip
                      title={
                        item?.displayName  ?
                        item?.displayName == 'No Data' ? 'No Data' :item?.displayName?.toUpperCase()
                        : item?.name == 'No Data' ? 'No Data' :  item?.name?.toUpperCase()
                      }
                    >
                      <div
                        className={`${
                          labelPosition === 'right'
                            ? 'flex-[100%]'
                            : labelFullWidth
                            ? 'flex-[100%]'
                            : 'flex min-w-[100px]'
                        }`}
                        key={index}
                      >
                        <div
                          className="inline-block w-6 h-1 rounded-[10px] align-middle mr-2.5 counter-chart-icon"
                          style={{ background: colors[itemType]?.color }}
                        ></div>
                        <Typography
                          variant={'caption'}
                          className={`inline-block capitalize ${
                            labelPosition === 'bottom' && 'text-[13px] counter-chart-label'
                          } `}
                          title={
                            item?.displayName  ?
                            item?.displayName == 'No Data' ? 'No Data' :item?.displayName?.toUpperCase()
                            : item?.name == 'No Data' ? 'No Data' :  item?.name?.toUpperCase()
                          }
                        >
                          {item.value}{' '}
                          {item?.displayName  ?
                            item?.displayName == 'No Data' ? 'No Data' :item?.displayName?.toUpperCase()
                            : item?.name == 'No Data' ? 'No Data' :  item?.name?.toUpperCase()}
                        </Typography>
                      </div>
                    </MuiTooltip>
                  ) : (
                    <div
                      className={`${
                        labelPosition === 'right'
                          ? 'flex-[100%]'
                          : labelFullWidth
                          ? 'flex-[100%]'
                          : 'flex'
                      }`}
                      key={index}
                    >
                      <div
                        className="inline-block w-6 h-1 rounded-[10px] align-middle mr-2.5 counter-chart-icon"
                        style={{ background: colors[itemType]?.color }}
                      ></div>
                      <Typography
                        variant={'caption'}
                        className={`inline-block capitalize ${
                          labelPosition === 'bottom' && 'text-[13px] counter-chart-label'
                        } `}
                        title={
                          item?.displayName  ?
                          item?.displayName == 'No Data' ? 'No Data' :item?.displayName?.toUpperCase()
                          : item?.name == 'No Data' ? 'No Data' :  item?.name?.toUpperCase()
                        }
                      >
                        {item.value}{' '}
                        {       item?.displayName  ?
                        item?.displayName == 'No Data' ? 'No Data' :item?.displayName?.toUpperCase()
                        : item?.name == 'No Data' ? 'No Data' :  item?.name?.toUpperCase()}
                      </Typography>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}

      {showEmpty && total <= 0 && (
        <div className="body1 flex flex-align-item-center flex-align-center h-full w-full text-body font-semibold text-align-center">
          -No Data to Display-
        </div>
      )}
    </Paper>
  );
};

CounterChartWidget.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  chartClassName: PropTypes.string,
  data: PropTypes.array,
  colors: PropTypes.object,
  labelPosition: PropTypes.oneOf(['right', 'bottom']),
  labelFullWidth: PropTypes.bool,
  height: PropTypes.number,
  showCustomLegendText: PropTypes.bool,
  showNoDataToolTip: PropTypes.bool
};

export default memo(CounterChartWidget, areEqualProps);
